/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "primeng/resources/primeng.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeicons/primeicons.css";


@font-face {
    font-family: 'Dosis';
    src: url('assets/fonts/Dosis/Dosis-Medium.ttf') format('truetype');
    /* Replace with the URL to your font file(s) */
    /* Add additional formats if necessary, e.g., .ttf and .otf */
    font-weight: 400
}

@font-face {
    font-family: 'Dosis';
    src: url('assets/fonts/Dosis/Dosis-Light.ttf') format('truetype');
    /* Replace with the URL to your font file(s) */
    font-weight: 200
}

@font-face {
    font-family: 'Dosis';
    src: url('assets/fonts/Dosis/Dosis-SemiBold.ttf') format('truetype');
    /* Replace with the URL to your font file(s) */
    font-weight: 700
}

@font-face {
    font-family: 'Dosis';
    src: url('assets/fonts/Dosis/Dosis-Bold.ttf') format('truetype');
    /* Replace with the URL to your font file(s) */
    font-weight: 800
}

@font-face {
    font-family: 'EuclidCircularA';
    src: url('assets/fonts/EuclidCircularA/EuclidCircularA-Light.ttf') format('truetype');
    /* Replace with the URL to your font file(s) */
    font-weight: 200
}

@font-face {
    font-family: 'EuclidCircularA';
    src: url('assets/fonts/EuclidCircularA/EuclidCircularA-Regular.ttf') format('truetype');
    /* Replace with the URL to your font file(s) */
    font-weight: 400
}

@font-face {
    font-family: 'EuclidCircularA';
    src: url('assets/fonts/EuclidCircularA/EuclidCircularA-Bold.ttf') format('truetype');
    /* Replace with the URL to your font file(s) */
    font-weight: 800
}

* {
    font-family: 'Dosis', sans-serif;
    --primary: '#37405B';
    --primary100: '#535B73';
    --secondary: '#C3363A';
    --secondary100: '#CD696C';
    font-size: 20px;
}

p {
    font-size: 20px;
}

.ffDosis {
    font-family: 'Dosis';
}

.ffEuclid {
    font-family: 'EuclidCircularA' !important;
}

.p-accordion .p-accordion-header .p-accordion-header-link svg {
    display: none;
}

.p-accordion .p-accordion-header .p-accordion-header-link .visible svg {
    display: block;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    border: none;
}


.p-accordion .p-accordion-header .p-accordion-header-link {
    border: none;
}

.p-accordion .p-accordion-content {
    border: none;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    background: #F0EDED;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #F0EDED;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    color: var(--primary)
}

.p-splitbutton .p-splitbutton-defaultbutton,
.p-splitbutton.p-button-rounded>.p-splitbutton-defaultbutton.p-button,
.p-splitbutton.p-button-outlined>.p-splitbutton-defaultbutton.p-button {
    display: none;
}

.p-button:enabled:hover {
    background: #C3363A;
    border: 1px solid #C3363A;
    color: white;
}
span.p-badge{
    background: #C3363A;
    font-weight: 400;
    font-size: 16px;
    font-style:normal

}

.p-splitbutton-menubutton,
.p-splitbutton.p-button-rounded>.p-splitbutton-menubutton.p-button,
.p-splitbutton.p-button-outlined>.p-splitbutton-menubutton.p-button {
    border-radius: 50%;
    color: #C3363A;
    border: 1px solid #C3363A;
}

//FULL CALENDAR TRANSFORMATION

.fc.fc-theme-standard .fc-view-harness th {
    background: #fff;
    border-color: #535B73;
}

.fc.fc-theme-standard .fc-view-harness td {
    border-color: #535B73;
}

.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
    border-color: #535B73;
}

.fc .fc-scrollgrid,
.fc .fc-scrollgrid table {
    border-color: #535B73;
}

.fc .fc-col-header-cell-cushion {
    text-transform: uppercase;
    padding: 10px 20px;
}

.fc-daygrid-day-number {
    font-weight: 200;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main {
    color: #C3363A;
    font-weight: 200;
    cursor: pointer;
}

.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title::before {
    content: '';
    left: .9rem;
    top: 40%;
    min-width: 5px;
    min-height: 5px;
    border-radius: 50%;
    background-color: #C3363A;
    position: absolute;
}

.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
    display: list-item;
    list-style-type: circle;
    padding-left: 1.3em;
}

.fc-day-today {
    background-color: #dddddd30 !important
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
    background: #fff;
    border-color: #fff;
}

.p-checkbox .p-checkbox-box.p-highlight:hover {
    border-color: #84DB92;
    background: #84DB92;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #84DB92;
    background: #84DB92;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #84DB92;
    background: #84DB92;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #84DB92;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #84DB92;
    background: #84DB92;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {

    background: #535B73;
}

.p-inputswitch .p-inputswitch-slider {
    background: #535B73;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: 0 0 10px #84db93d6;
}


.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {

    background: #84DB92;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #84DB92;
}


.clasesContainer .p-accordion .p-accordion-header .p-accordion-header-link {
    background: #535B73 !important;
    color: #fff;
    padding: 10px;
}

.clasesContainer .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #535B73 !important;
    color: #fff;
    padding: 10px;
}

.clasesContainer .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #535B73 !important;
    color: #fff;
    padding: 10px;
}

.clasesContainer .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: #535B73 !important;
    color: #fff;
}

.p-inputtext:enabled:focus {
    border: #C3363A 1px solid;
    box-shadow: 0 0 5px #c3363bd6;
}

.p-inputtext:enabled:hover {
    border-color: #e02c32;
}

.p-inputtext {
    border: #C3363A 1px solid;
    font-size: 16px;
    padding: 10px;
}


button:disabled {
    opacity: .5;
    pointer-events: none;
}

p-inputSwitch {
    display: flex;
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #535B73;
}

p-fileupload .p-fileupload {

    display: flex;
    flex-direction: column-reverse;
}

.p-fileupload .p-fileupload-buttonbar {
    border: none;
    background: #fff;
}

.p-fileupload .p-fileupload-content {
    border: red 2px dashed;
    border-radius: 5px;
}

.p-fileupload-content .p-progressbar {
    display: none;
}

.p-button.p-button-icon-only {
    color: #C3363A;
    border: 1px solid #C3363A
}

.p-fileupload .p-fileupload-buttonbar .p-button:hover {
    color: #fff;
    background-color: #535B73;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
    background: transparent;
    border: 2px solid #535B73;
    color: #535B73;
    transition: .5s ease all;
    padding: 10px 20px;
}

.p-fileupload .p-fileupload-buttonbar {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.p-radiobutton-box{
    border: 1px solid grey;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #84DB92;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    opacity: .8;
    border-color: #84DB92;
    background: #84DB92;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #84DB92;
    background: #84DB92;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: grey;
    border-color: #84DB92;
}

input:focus-visible {
    outline: none;
    border-bottom-width: 3px;
    
}


.ck-focused{
    border:none;
    outline: none;
}

p ul{
    padding-left: 30px;
}
p ul li{
    list-style-type: disc;
}
iframe{
    background-color: lightgray;
}

.p-paginator-page{
    border-radius: 0;
    border: 1px solid #C3363A;
    border-right: none;
}
.p-paginator-page:last-child{
    border: 1px solid #C3363A;
    border-right: 1px solid #C3363A;
}
.p-paginator-page.p-highlight{
    background: #C3363A;
    color: white;
}
.p-tooltip{
    padding-left: 5px;
}